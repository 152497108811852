import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { map, Subject, switchMap, takeUntil, tap } from 'rxjs'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { AjastettuTyo, AjastettuTyoService, AjastetunTyonTyyppi } from 'app/_jaettu/eraajot/ajastettu/ajastetut-tyot.service'
import { MatDialog } from '@angular/material/dialog'
import { AjastetutTyotDataDialog } from './ajastetut-tyot-data.dialog'

interface AjastetutTyotForm {
  tyyppi: FormControl<string>
}
@Component({
  templateUrl: './yllapito-ajastetut-tyot.component.html',
  styleUrls: ['./yllapito-ajastetut-tyot.component.css'],
  providers: [
  ],
})
export class YllapitoAjastetutTyotComponent implements OnInit, OnDestroy, AfterViewInit {

  naytettavatKolumnit: string[] = ['linkki', 'asiakasAvain', 'kayttajaAvain', 'ajoaika', 'selite']

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  hakuform: FormGroup<AjastetutTyotForm>

  @ViewChild(MatSort, { static: true }) _sort: MatSort
  dataSource = new MatTableDataSource<any>([])
  private _kirjanpitajaOnDevaaja: boolean

  ajastetunTyonTyyppi: { tyyppi: AjastetunTyonTyyppi, teksti: string }[] = []

  constructor(
    private _firebaseLemonator: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _dialog: MatDialog,
    private _ajastettuTyoService: AjastettuTyoService
  ) { }

  ngOnInit() {

    this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(onkoDevaaja => {
      this._kirjanpitajaOnDevaaja = onkoDevaaja
    })

    this.ajastetunTyonTyyppi = this._ajastettuTyoService.getAjastetunTyonTyypit()

    this.hakuform = new FormGroup<AjastetutTyotForm>({
      tyyppi: new FormControl<string>(null)
    })

    const tyyppiControl = this.hakuform.get('tyyppi')

    tyyppiControl.valueChanges.pipe(
      tap(() => this._ladataanService.aloitaLataaminen()),
      switchMap((tyyppi) => {
        if (!tyyppi) {
          return []
        }

        return this._firebaseLemonator.firestoreCollection<AjastettuTyo<any>>('tyojono-ajastetut')
          .orderBy('ajoaika', 'desc').listen().pipe(
            map(tyot => {
              // Overwrite selite field with standard explanation
              for (const tyo of tyot) {
                tyo.selite = this.ajastetunTyonTyyppi.find(t => t.tyyppi === tyo.tyyppi).teksti
              }

              if (tyyppi && tyyppi !== 'all') {
                return tyot.filter(tyo => tyo.tyyppi === tyyppi)
              }
              return tyot
            })
          )
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tyot => {
      this.dataSource.data = tyot
      this._ladataanService.lopetaLataaminen()
    })

    tyyppiControl.setValue('all')

  }

  ngAfterViewInit() {
    this.dataSource.sort = this._sort
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  katsoTiedot(rivi: AjastettuTyo<any>) {
    if (this._kirjanpitajaOnDevaaja) {
      this._dialog.open(AjastetutTyotDataDialog, { data: rivi })
    }
  }

  openLink(asiakasAvain: string, event: Event) {
    event.preventDefault()
    event.stopPropagation()

    window.open('https://lemonator.lemontree.fi/asiakkaat/' + asiakasAvain + '/asiakastiedot', '_blank')

  }

}
