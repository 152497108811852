<div class="preview-container">
  <div class="tiny-title">Esikatselu</div>
  <div class="header-container">
    <div class="blank-space">&nbsp;</div>
    <div class="lemon-name">Tilitoimisto Lemon Tree Oy</div>
    <div class="blank-space">&nbsp;</div>
    <div class="blank-space">&nbsp;</div>
    <div class="mail-title">
      {{otsikkoObservable | async}}
    </div>
    <div class="blank-space">&nbsp;</div>
    <div class="blank-space">&nbsp;</div>
  </div>

  <div style="max-width: 500px; margin: 0 auto; padding: 10px;">
    <div [innerHTML]="htmlObservable | async"></div>
  </div>
</div>