// eslint-disable-next-line no-shadow
export enum RaporttiType {
  TULOS = '1',
  TASE = '2',
  PAAKIRJA = '3',
  TULOS_VIRALLINEN = '4',
  TASE_VIRALLINEN = '5',
  VEROILMOITUS = '6',
  SELVITETTAVAT_OSTOT = '7',
  SELVITETTAVAT_MYYNNIT = '8',
  PAIVAKIRJA = '9',
  ALV_ILMOITUS = '10',
  TASE_ERITTELY = '11',
  TULOS_JA_TASE_VIRALLINEN = '12',
  OSS = '13',
  TILIOTE = '14',
  TILINPAATOS = '15',
  YHTIOKOKOUKSEN_POYTAKIRJA = '16',
  PRH_TILINPAATOSILMOITUS = '17',
  LUETTELO_KIRJANPIDOISTA_JA_AINEISTOISTA = '18',
  TILIKARTTA = '19',
  HALLITUKSEN_POYTAKIRJA = '20',
  TULOS_BRUTTO = '21',
  RESKONTRA = '22'
}
