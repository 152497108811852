<div class="list-page kapea" style="max-width: 1500px;">
  <h2 class="header">Ajastetut työt</h2>

  <form [formGroup]="hakuform" novalidate>

    <div class="ylaosan-kontrollit" style="margin: 0; justify-content: space-between; align-items: center;">
      <div class="hakukontrollit" style="align-items: baseline;">

        <mat-form-field floatPlaceholder="always" style="width: 570px; margin-right: 1.5vw;">
          <mat-label>Tyyppi</mat-label>
          <mat-select formControlName="tyyppi">
            <mat-option [value]="'all'">Kaikki</mat-option>
            <mat-option *ngFor="let type of ajastetunTyonTyyppi" [value]="type.tyyppi">
              {{ type.teksti }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>
  </form>

  <div style="width:100%;">
    <div style="position: relative; width: 100%;">
      <mat-table mat-table multiTemplateDataRows matSort [dataSource]="dataSource" [matSortDisableClear]="true" style="min-height: 94px;">

        <ng-container matColumnDef="linkki">
          <th mat-header-cell *matHeaderCellDef style="min-width: 30px;"> &nbsp; </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 30px;">
            <tr>
              <td>
                <span (click)="openLink(row.asiakasAvain, $event)" class="fa fa-external-link link-btn" matTooltip="Avaa asiakkaan tiedot uudella välilehdellä"></span>
              </td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="asiakasAvain">
          <th mat-header-cell *matHeaderCellDef style="min-width: 220px;"> Asiakas </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 220px;">
            <tr>
              <td>{{ row.asiakasAvain || 'Ei asiakasta' }}</td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="kayttajaAvain">
          <th mat-header-cell *matHeaderCellDef style="min-width: 200px;"> Käyttäjä </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 200px;">
            <tr>
              <td>{{ row.kayttajaAvain || 'Ei käyttäjää' }}</td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="ajoaika">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 180px;"> Ajoaika </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 180px;">
            <tr>
              <td>{{ (row.ajoaika.toDate() | ldatetime | async) || 'Ei saatavilla' }}</td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- <ng-container matColumnDef="tyyppi">
          <th mat-header-cell *matHeaderCellDef style="min-width: 250px;"> Tyyppi </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 250px;">
            <tr>
              <td>{{ row.tyyppi || 'Ei saatavilla' }}</td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container> -->

        <ng-container matColumnDef="selite">
          <th mat-header-cell *matHeaderCellDef style="min-width: 200px;"> Selite </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 200px;">
            <tr>
              <td>{{ row.selite || 'Ei saatavilla' }}</td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="naytettavatKolumnit"></mat-header-row>
        <mat-row (click)="katsoTiedot(row)" *matRowDef="let row; columns: naytettavatKolumnit;"></mat-row>

      </mat-table>
    </div>
  </div>
</div>