import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { Validators, FormControl, FormGroup } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'

import { Subject, Observable, firstValueFrom, of, BehaviorSubject } from 'rxjs'
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators'

import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { DateService } from '../../_shared-core/service/date.service'

import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { LahetaMassaviestiTestRequest, LahetaMassaviestiTestResponse, Massaviesti, MassaviestiTemplate, MassaviestiTyojono, MassaviestiTyojonoData } from '../../_jaettu-lemonator/model/massaviesti'
import { QuillEditorComponent, QuillModules } from 'ngx-quill'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { MassaviestiUriService } from 'app/_jaettu-lemonator/service/massaviesti-uri.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { DomSanitizer } from '@angular/platform-browser'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

interface RecipientPropertiesForm {
  'sopimus-voimassa': FormControl<boolean>
  'tauolla': FormControl<boolean>
  'normiasiakas': FormControl<boolean>
  'zen': FormControl<boolean>
  'minduu': FormControl<boolean>
}

interface MainForm {
  aiheFi: FormControl<string>
  aiheEn: FormControl<string>
  otsikkoFi: FormControl<string>
  otsikkoEn: FormControl<string>
  tekstiFi: FormControl<string>
  tekstiEn: FormControl<string>
  recipients: FormGroup<RecipientPropertiesForm>
}

@Component({
  templateUrl: './massaviesti.component.html',
  styleUrls: ['./massaviesti.component.css']
})
export class MassaviestiComponent implements OnInit, OnDestroy {

  private _ngUnsubsribe: Subject<void> = new Subject<void>()

  private _latestMassaviestiSubject: BehaviorSubject<Massaviesti> = new BehaviorSubject(null)

  usableVariablesSuomiObservable: Observable<string[]>
  usableVariablesEnglishObservable: Observable<string[]>
  lahetyksiaJonossaObservable: Observable<number>

  aiheFi: FormControl<string> = new FormControl({ value: '', disabled: false }, Validators.required)
  aiheEn: FormControl<string> = new FormControl({ value: '', disabled: false }, Validators.required)
  otsikkoFi: FormControl<string> = new FormControl({ value: '', disabled: false }, Validators.required)
  otsikkoEn: FormControl<string> = new FormControl({ value: '', disabled: false }, Validators.required)
  tekstiFi: FormControl<string> = new FormControl({ value: '', disabled: false }, Validators.required)
  tekstiEn: FormControl<string> = new FormControl({ value: '', disabled: false }, Validators.required)

  htmlFiObservable: Observable<string>
  htmlEnObservable: Observable<string>

  recipients: FormGroup<RecipientPropertiesForm> = new FormGroup<RecipientPropertiesForm>({
    'sopimus-voimassa': new FormControl({ value: true, disabled: true }, Validators.requiredTrue),
    'tauolla': new FormControl(false),
    'normiasiakas': new FormControl(true),
    'zen': new FormControl({ value: false, disabled: true }),
    'minduu': new FormControl(false)
  })

  form: FormGroup<MainForm> = new FormGroup<MainForm>({
    'aiheFi': this.aiheFi,
    'aiheEn': this.aiheEn,
    'otsikkoFi': this.otsikkoFi,
    'otsikkoEn': this.otsikkoEn,
    'tekstiFi': this.tekstiFi,
    'tekstiEn': this.tekstiEn,
    'recipients': this.recipients
  })

  modules: QuillModules = {
    toolbar: [
      [{ 'header': 2 }, 'bold', 'italic', 'underline', { 'list': 'ordered' }, { 'list': 'bullet' }],
      ['clean']
    ]
  }

  templateFiCache: MassaviestiTemplate = {
    aihe: null,
    otsikko: null,
    html: null,
    kieli: 'fi'
  }
  templateEnCache: MassaviestiTemplate = {
    aihe: null,
    otsikko: null,
    html: null,
    kieli: 'en'
  }

  @ViewChild('quillFi', { read: QuillEditorComponent, static: true }) quillFi: QuillEditorComponent

  constructor(
    private _formValidationService: FormValidationService,
    private _firebase: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _timestampService: TimestampService,
    private _dateService: DateService,
    private _snackbar: MatSnackBar,
    private _dialog: MatDialog,
    private _massaviestiUriService: MassaviestiUriService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _copyService: KopioijaPalvelu,
    private _domSanitizer: DomSanitizer
  ) { }



  ngOnInit() {
    this.aiheFi.valueChanges.pipe(takeUntil(this._ngUnsubsribe)).subscribe(val => this.templateFiCache.aihe = val)
    this.aiheEn.valueChanges.pipe(takeUntil(this._ngUnsubsribe)).subscribe(val => this.templateEnCache.aihe = val)
    this.otsikkoFi.valueChanges.pipe(takeUntil(this._ngUnsubsribe)).subscribe(val => this.templateFiCache.otsikko = val)
    this.otsikkoEn.valueChanges.pipe(takeUntil(this._ngUnsubsribe)).subscribe(val => this.templateEnCache.otsikko = val)
    this.tekstiFi.valueChanges.pipe(takeUntil(this._ngUnsubsribe)).subscribe(val => this.templateFiCache.html = this._formatQuillOutput(val))
    this.tekstiEn.valueChanges.pipe(takeUntil(this._ngUnsubsribe)).subscribe(val => this.templateEnCache.html = this._formatQuillOutput(val))

    this.htmlFiObservable = this.tekstiFi.valueChanges.pipe(map(html => this._domSanitizer.bypassSecurityTrustHtml(this._formatQuillOutput(html ?? '')) as string))
    this.htmlEnObservable = this.tekstiEn.valueChanges.pipe(map(html => this._domSanitizer.bypassSecurityTrustHtml(this._formatQuillOutput(html ?? '')) as string))

    this._firebase.firestoreCollection<Massaviesti>(this._massaviestiUriService.getMassaviestiCollection()).where('lahetetty', '==', null).listen().pipe(
      take(1)
    ).subscribe(data => {
      if (data?.length) {
        const sorted = data.sort((a, b) => a.paivitetty.toMillis() - b.paivitetty.toMillis())
        const latest = sorted[0]

        this._latestMassaviestiSubject.next(latest)

        this._setForm(latest)

      } else {
        this._latestMassaviestiSubject.next({
          avain: this._firebase.firestoreCreateId(), // Create new version
          templates: { fi: null, en: null },
          recipientsProperties: {},
          paivitetty: this._timestampService.now(),
          lahetetty: null
        })
      }
    })

    this.lahetyksiaJonossaObservable = this._latestMassaviestiSubject.asObservable().pipe(
      switchMap(massaviesti => {
        if (!massaviesti) {
          return of(0)
        }
        return this._firebase.firestoreCollection<MassaviestiTyojonoData>(this._massaviestiUriService.getMassaviestiSendTyojonoCollection(massaviesti.avain)).listenSnapshots().pipe(
          map(snapshots => snapshots?.length ?? 0)
        )
      }),
      startWith(0),
      lemonShare()
    )
  }

  async save(preSendSave?: boolean) {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    const toSave: Massaviesti = this._copyService.cloneObjectDeep(this._latestMassaviestiSubject.value)

    toSave.templates.fi = this.templateFiCache
    toSave.templates.en = this.templateEnCache

    toSave.recipientsProperties = {} // Reset field

    const sopimusVoimassa = this.recipients.get('sopimus-voimassa').value
    if (sopimusVoimassa) { toSave.recipientsProperties.SOPIMUS_VOIMASSA = sopimusVoimassa }

    const tauolla = this.recipients.get('tauolla').value
    if (tauolla) { toSave.recipientsProperties.TAUOLLA_TAI_POYTALAATIKOSSA = tauolla }

    const normiasiakas = this.recipients.get('normiasiakas').value
    if (normiasiakas) { toSave.recipientsProperties.NORMIASIAKAS = normiasiakas }

    const zen = this.recipients.get('zen').value
    if (zen) { toSave.recipientsProperties.ZEN = zen }

    const minduu = this.recipients.get('minduu').value
    if (minduu) { toSave.recipientsProperties.MINDUU = minduu }

    await this._firebase.firestoreSetData<Massaviesti>(this._massaviestiUriService.getMassaviestiUri(toSave.avain), toSave)

    this._latestMassaviestiSubject.next(toSave) // Important! Update cache

    this._ladataanService.lopetaLataaminen()

    if (!preSendSave) {
      this._snackbar.open('Tallentaminen onnistui', 'OK', { duration: 5000 })
    }
  }

  async send() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    // const tauolla = this.recipients.get('tauolla').value
    // const normiasiakas = this.recipients.get('normiasiakas').value
    // const zen = this.recipients.get('zen').value
    // const minduu = this.recipients.get('minduu').value

    // const groups = [(normiasiakas ? 'normiasiakkaille' : null), (zen ? 'Zen-asiakkaille' : null), (minduu ? 'Minduu-asiakkaille' : null)].filter(val => val)
    const areYouSureText = `Haluatko aloittaa massaviestin ${this.aiheFi.value} lähettämisen asiakkaille? ` // Viesti lähetetään ${groups.join(', ')}, joilla on sopimus voimassa ${tauolla ? ' + neille, jotka ovat tauolla vai pöytälaatikossa.' : '.'} `

    const isSure = await firstValueFrom(this._dialog.open<AreYouSureDialog, AreYouSureDialogData>(AreYouSureDialog, { data: { text: areYouSureText, rightAction: 'Lähetä' } }).afterClosed())
    if (!isSure) {
      return
    }

    await this.save(true)

    const massaviestiAvain = this._latestMassaviestiSubject.value.avain
    const tyojonoDoc = this._massaviestiUriService.getMassaviestiSendTyojonoUri(massaviestiAvain, massaviestiAvain)
    const tyojonoData: MassaviestiTyojono = {
      aloitettu: this._timestampService.now(),
      massaviestiAvain: massaviestiAvain
    }
    await this._firebase.firestoreSetData(tyojonoDoc, tyojonoData)

    this._snackbar.open('Lähettäminen aloitettu', 'OK', { duration: 5000 })
  }

  async test() {
    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    await this.save(true)

    try {

      const requestData: LahetaMassaviestiTestRequest = {
        massaviestiAvain: this._latestMassaviestiSubject.value.avain
      }

      const resp = await this._firebase.functionsCall<LahetaMassaviestiTestRequest, LahetaMassaviestiTestResponse>('testMassaviestiSend', requestData)

      if (!resp || resp.e) {
        throw new Error('Test send failed! ' + resp?.e ?? 'no-response')
      } else {
        this._snackbar.open('Testiviesti lähetetty', 'OK', { duration: 5000 })
      }

    } catch (err) {
      console.error(err)
      this._snackbar.open('Testiviestin lähettäminen epäonnistui. Ole hyvä ja yritä uudelleen.', 'OK', { duration: 5000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private _setForm(massaviesti: Massaviesti) {
    this.aiheFi.setValue(massaviesti.templates.fi.aihe)
    this.aiheEn.setValue(massaviesti.templates.en.aihe)
    this.otsikkoFi.setValue(massaviesti.templates.fi.otsikko)
    this.otsikkoEn.setValue(massaviesti.templates.en.otsikko)
    this.tekstiFi.setValue(massaviesti.templates.fi.html)
    this.tekstiEn.setValue(massaviesti.templates.en.html)

    this.recipients.get('sopimus-voimassa').setValue(massaviesti.recipientsProperties.SOPIMUS_VOIMASSA)
    this.recipients.get('tauolla').setValue(massaviesti.recipientsProperties.TAUOLLA_TAI_POYTALAATIKOSSA)
    this.recipients.get('normiasiakas').setValue(massaviesti.recipientsProperties.NORMIASIAKAS)
    this.recipients.get('zen').setValue(massaviesti.recipientsProperties.ZEN)
    this.recipients.get('minduu').setValue(massaviesti.recipientsProperties.MINDUU)
  }

  private _formatQuillOutput(html: string) {
    // Surround with div for styling
    html = ('  <div style="font-size: 17px; line-height: 30px;">').concat(html)
    html = html.concat('</div')

    html = html.replaceAll('<p class=\"ql-align-justify\"></p>', '<br/>') // Quill uses empty paragraphs instead of breaks, replace those
      .replace(/style="[^"]*color:\s*rgb\(\d{1,3},\s*\d{1,3},\s*\d{1,3}\);\s*[^"]*"/, '') // Quill adds extra colors everywhere, strip those.
      .replaceAll('<h2 ', '<h2 style="margin-top: 40px; color: rgb(45, 178, 223)" ') // Add our own special styling for h2 elements

    return html
  }

  ngOnDestroy() {
    this._ngUnsubsribe.next()
  }

}
