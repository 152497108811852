export class MassaviestiUriService {
  getMassaviestiCollection() {
    return 'massaviesti/'
  }
  getMassaviestiUri(massaviestiAvain: string) {
    return this.getMassaviestiCollection() + massaviestiAvain
  }

  getMassaviestiSendsMainLogCollection(massaviestiAvain: string) {
    return this.getMassaviestiUri(massaviestiAvain) + '/massaviestin-lahetys-asiakkaalle'
  }

  getMassaviestiPerCustomerSendLogCollection(massaviestiAvain: string, asiakasAvain: string) {
    return this.getMassaviestiSendsMainLogCollection(massaviestiAvain) + '/' + asiakasAvain
  }
  getMassaviestiPerRecipientSendLogCollection(massaviestiAvain: string, asiakasAvain: string) {
    return this.getMassaviestiPerCustomerSendLogCollection(massaviestiAvain, asiakasAvain) + '/massaviestin-lahetys-vastaanottajalle'
  }

  getMassaviestiPerRecipientSendLogUri(massaviestiAvain: string, asiakasAvain: string, sendAvain: string) {
    return this.getMassaviestiPerRecipientSendLogCollection(massaviestiAvain, asiakasAvain) + '/' + sendAvain
  }

  getMassaviestiSendTyojonoCollection(massaviestiAvain: string) {
    return 'tyojono_data/' + massaviestiAvain + '/massaviestin-lahettaminen'
  }

  getMassaviestiSendTyojonoDataUri(massaviestiAvain: string, asiakasAvain: string) {
    return this.getMassaviestiSendTyojonoCollection(massaviestiAvain) + '/' + asiakasAvain
  }

  getMassaviestiSendTyojonoUri(massaviestiAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + massaviestiAvain + '/laheta-massaviesti/' + tyojonoAvain
  }
}