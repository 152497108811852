import { Routes, RouterModule } from '@angular/router'

import { LoginComponent } from './login/login.component'
import { LoggedOutComponent } from './login/loggedout.component'

import { AuthGuard } from './_angular/_guards/auth.guard'
import { YllapitoGuard } from './_angular/_guards/yllapito.guard'

import { AsiakkaatComponent } from './asiakkaat/listaus/asiakkaat.component'
import { AsiakasLisaaComponent } from './asiakkaat/asiakas-lisaa.component'
import { AsiakasComponentDataResolve, KayttajaComponentDataResolve, KirjanpitoComponentDataResolve } from './_angular/_resolvers/asiakas.resolve'
import { AsiakkaanKayttajatComponent } from './asiakkaat/asiakkaan-kayttajat.component'
import { AsiakkaanMaksutavatComponent } from './asiakkaat/asiakkaan-maksutavat.component'
import { AsiakkaanTositteetComponent } from './asiakkaat/asiakkaan-tositteet.component'
import { KayttajaComponent } from './asiakkaat/kayttaja.component'

import { AsiakasAsetuksetAsiakastiedotComponent } from './asiakkaat/asetukset/asiakastiedot.component'
import { AsiakasAsetuksetLaskutusComponent } from './asiakkaat/asetukset/laskutus.component'
import { AsiakasAsetuksetSopimuskaudetComponent } from './asiakkaat/asetukset/sopimuskaudet.component'
import { AsiakasAsetuksetKirjanpitotiedotComponent } from './asiakkaat/asetukset/kirjanpitotiedot.component'
import { AsiakasAsetuksetTuntemistiedotComponent } from './asiakkaat/asetukset/tuntemistiedot/tuntemistiedot.component'

import { AjopaivakirjaContainerComponent } from './asiakkaat/ajopaivakirja/ajopaivakirja-container.component'
import { ArkistoComponent } from './asiakkaat/arkisto/arkisto.component'

import { AsiakkaanKirjanpitoLahetyksetComponent } from './asiakkaat/yllapito/kirjanpito-lahetykset.component'
import { TilastotComponent } from './tilastot/tilastot.component'

import { TositeSelailuListausComponent } from './tositteet/selailu/tosite.listaus.component'
import { TositeSelailuKatseleComponent } from './tositteet/selailu/tosite.katsele.component'
import { TositeKatseleComponentDataResolve } from './_angular/_resolvers/tosite.resolve'
import { YhteisomyyntilaskutComponent } from './yllapito/yhteisomyyntilaskut.component'
import { AsiakasGuard } from './_angular/_guards/asiakas.guard'
import { LaskuComponent } from './laskut/lasku.component'
import { LaskuComponentDataResolve, LaskuKatseleComponentDataResolve, LaskuSelaaKatseleComponentDataResolve } from './_angular/_resolvers/lasku.resolve'
import { LaskuKatseleComponent } from './laskut/lasku.katsele.component'

import { TilitapahtumatComponent } from './yllapito/tilitapahtumat/tilitapahtumat.component'
import { HolviImportComponent } from './yllapito/holvi-import/holvi-import.component'

import { KirjanpitajatComponent } from './yllapito/kirjanpitajat/kirjanpitajat.component'
import { KirjanpitajaComponent } from './yllapito/kirjanpitajat/kirjanpitaja.component'
import { KirjanpitajaComponentDataResolve } from './_angular/_resolvers/kirjanpitaja.resolve'

import { AsiakkaanApixLaskujenTiedotComponent } from './asiakkaat/yllapito/apix-laskujen-tiedot.component'
import { AsiakkaanLemonaidLaskuasetuksetComponent } from './asiakkaat/yllapito/lemonaid-laskuasetukset.component'

import { KirjanpitoComponent } from './kirjanpito/kirjanpito.component'
import { KirjanpitoRaportitStandaloneComponent } from './kirjanpito/raportit-standalone.component'
import { KirjanpitoTositeStandaloneComponent } from './kirjanpito/tositteet/tosite-standalone.component'

import { PaatilikarttaComponent } from './yllapito/tilikartta/paatilikartta.component'
// import { AsiakkaanTilikarttaComponent } from './asiakkaxat/tilikartta/asiakkaan-tilikartta.component'
import { TilitapahtumienKohdistusdebugComponent } from './yllapito/tilitapahtumat/tilitapahtumien-kohdistusdebug.component'
import { YllapitoVerohallintoComponent } from './yllapito/integraatiot/verohallinto.component'
import { YllapitoHolviComponent } from './yllapito/integraatiot/holvi.component'
import { KayttoehdotComponent } from './yllapito/kayttoehdot/kayttoehdot.component'
import { SopimuksetComponent } from './yllapito/sopimukset/sopimukset.component'
import { AsiakasOmaveroMaksutiedotComponent } from './asiakkaat/yllapito/omavero-maksutiedot.component'
import { DmarcRaportitComponent } from './yllapito/postmarkapp/dmarc-raportit.component'
import { DmarcRaporttiComponent } from './yllapito/postmarkapp/dmarc-raportti.component'
import { KirjanpitoAineistonLatausComponent } from './kirjanpito/aineiston-lataus/kirjanpito-aineiston-lataus.component'
import { AsiakkaatComponentOld } from './asiakkaat/listaus/asiakkaat.component-old'
import { PankkiyhteydetComponent } from './yllapito/pankkiyhteydet/pankkiyhteydet.component'
import { PaivasaldoListausComponent } from './yllapito/tilitapahtumat/paivasaldot-listaus.component'
import { AsiakkaanKirjanpidonAlustaminenComponent } from './asiakkaat/yllapito/kirjanpidon-alustaminen.component'
import { YhteisomyyntiIlmoituksetComponent } from './asiakkaat/yllapito/yhteisomyynti-ilmoitukset.component'
import { ReportsComponent } from './yllapito/raportit/reports.component'

import { KirjanpitajanAsetuksetComponent } from './kirjanpitaja/kirjanpitajan-asetukset.component'
import { AsiakashakuComponent } from './asiakkaat/asiakashaku.component'
import { AsiakasSalesListComponent } from './asiakkaat/sales.component'
import { EmailTemplatesComponent } from './yllapito/email-templates/email-templates.component'
import { MaksumuistutusComponent } from './yllapito/maksumuistutus/maksumuistutus.component'
import { LaskutSelaaComponent } from './laskut/selaa/laskut-selaa.component'
import { LaskuSelaaKatseleComponent } from './laskut/selaa/lasku-selaa.katsele.component'
import { BankConnectionsComponent } from './asiakkaat/yllapito/bank-connections.component'
import { YllapitoAjastetutTyotComponent } from './yllapito/ajastetut-tyot/yllapito-ajastetut-tyot.component'
import { AjastetutTyotComponent } from './asiakkaat/yllapito/ajastetut-tyot.component'
import { MassaviestiComponent } from './yllapito/massaviesti/massaviesti.component'

const appRoutes: Routes = [

  { path: '', component: AsiakkaatComponent, canActivate: [AuthGuard] },

  // Asiakkaat, käyttäjätyökalut
  { path: 'asiakkaat/:asiakasAvain/kayttajat/:kayttajaAvain', component: KayttajaComponent, canActivate: [AsiakasGuard], resolve: { 'data': KayttajaComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/kayttajat', component: AsiakkaanKayttajatComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/maksutavat', component: AsiakkaanMaksutavatComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/laskuasetukset', component: AsiakkaanLemonaidLaskuasetuksetComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },

  // Asiakkaat, tositteiden selaaminen
  { path: 'asiakkaat/:asiakasAvain/tositteet/selaa/:tositeAvain', component: TositeSelailuKatseleComponent, canActivate: [AsiakasGuard], resolve: { 'data': TositeKatseleComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/tositteet/selaa', component: TositeSelailuListausComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/tositteet/lataa', component: AsiakkaanTositteetComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },

  // Asiakkaat, laskujen selaaminen
  { path: 'asiakkaat/:asiakasAvain/laskut/selaa/:id/:kasiteltavaAvain', component: LaskuSelaaKatseleComponent, canActivate: [AsiakasGuard], resolve: { 'data': LaskuSelaaKatseleComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/laskut/selaa', component: LaskutSelaaComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },

  // Asiakkaat, asetukset, laskujen lähettäminen
  { path: 'asiakkaat/:asiakasAvain/laskutus/katsele/:id/:kasiteltavaAvain/:toiminto', component: LaskuKatseleComponent, canActivate: [AsiakasGuard], resolve: { 'data': LaskuKatseleComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/laskutus/katsele/:id/:kasiteltavaAvain', component: LaskuKatseleComponent, canActivate: [AsiakasGuard], resolve: { 'data': LaskuKatseleComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/laskutus/luo/:id/:kasiteltavaAvain', component: LaskuComponent, canActivate: [AsiakasGuard], resolve: { 'data': LaskuComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/laskutus', component: AsiakasAsetuksetLaskutusComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },

  // Asiakkaat, asetukset, muut
  { path: 'asiakkaat/:asiakasAvain/kirjanpitotiedot', component: AsiakasAsetuksetKirjanpitotiedotComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/asiakastiedot', component: AsiakasAsetuksetAsiakastiedotComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/tuntemistiedot', component: AsiakasAsetuksetTuntemistiedotComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/kirjanpidon-ulkopuoliset-vahennykset', component: AjopaivakirjaContainerComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/veronmaksu', component: AsiakasOmaveroMaksutiedotComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/sopimukset', component: AsiakasAsetuksetSopimuskaudetComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/tositteiden-arkisto', component: ArkistoComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },

  // Päänäkymät
  { path: 'jaettavat-asiakkaat', component: AsiakasSalesListComponent, canActivate: [AuthGuard] },
  { path: 'tilastot', component: TilastotComponent, canActivate: [AuthGuard] },
  { path: 'asiakkaat/uusi', component: AsiakasLisaaComponent, canActivate: [AuthGuard] },
  { path: 'asiakkaat/haku', component: AsiakashakuComponent, canActivate: [AuthGuard] },
  { path: 'asiakkaat', component: AsiakkaatComponentOld, canActivate: [AuthGuard] },
  { path: 'asiakkaat/uusi-lista', component: AsiakkaatComponent, canActivate: [AuthGuard] },
  { path: 'zen', component: AsiakkaatComponent, canActivate: [AuthGuard] },
  // { path: 'tyoajanseuranta', component: TyoajanseurantaComponent, canActivate: [AuthGuard], resolve: { 'data': TyoajanseurantaComponentDataResolve } },
  { path: 'kirjanpitajan-asetukset', component: KirjanpitajanAsetuksetComponent, canActivate: [AuthGuard] },

  // Työajanseuranta
  // { path: 'tyoajanseuranta/:kirjanpitajanAvain', component: TyoajanseurantaComponent, canActivate: [AuthGuard], resolve: { 'data': TyoajanseurantaComponentDataResolve } },
  // { path: 'tyoajanseuranta/:kirjanpitajanAvain/:vuosi/:kuukausi', component: TyoajanseurantaComponent, canActivate: [AuthGuard], resolve: { 'data': TyoajanseurantaComponentDataResolve } },

  // Kirjanpito
  // { path: 'asiakkaat/:asiakasAvain/tilikartta', component: AsiakkaanTilikarttaComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/kirjanpito/:vuosi/:kuukausi/:valilehti', component: KirjanpitoComponent, canActivate: [AsiakasGuard], resolve: { 'data': KirjanpitoComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/kirjanpito/:vuosi/:kuukausi', component: KirjanpitoComponent, canActivate: [AsiakasGuard], resolve: { 'data': KirjanpitoComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/kirjanpito', component: KirjanpitoComponent, canActivate: [AsiakasGuard], resolve: { 'data': KirjanpitoComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/kirjanpidon-raportit/:vuosi/:kuukausi/:tyyppi', component: KirjanpitoRaportitStandaloneComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/kirjanpidon-tosite/:tositeTyyppi/:tositeAvain', component: KirjanpitoTositeStandaloneComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },

  // Asiakaskohtaiset ylläpitotyökalut
  { path: 'asiakkaat/:asiakasAvain/yllapito/apixlaskut', component: AsiakkaanApixLaskujenTiedotComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/tilitapahtumat/kohdistusdebug', component: TilitapahtumienKohdistusdebugComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/tilitapahtumat', component: TilitapahtumatComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/pankkiyhteydet', component: BankConnectionsComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/lahetykset', component: AsiakkaanKirjanpitoLahetyksetComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/paivasaldot', component: PaivasaldoListausComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/aineiston-lataus', component: KirjanpitoAineistonLatausComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/holvi-import', component: HolviImportComponent, canActivate: [AsiakasGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/alusta-kirjanpito', component: AsiakkaanKirjanpidonAlustaminenComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/yhteisomyynti-ilmoitukset', component: YhteisomyyntiIlmoituksetComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },
  { path: 'asiakkaat/:asiakasAvain/ajastetut-tyot', component: AjastetutTyotComponent, canActivate: [AsiakasGuard, YllapitoGuard], resolve: { 'data': AsiakasComponentDataResolve } },


  // Yleinen ylläpito
  { path: 'yllapito/paatilikartta', component: PaatilikarttaComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/kirjanpitajat', component: KirjanpitajatComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/kirjanpitajat/:kirjanpitajanAvain', component: KirjanpitajaComponent, canActivate: [YllapitoGuard], resolve: { 'data': KirjanpitajaComponentDataResolve } },
  { path: 'yllapito/yhteisomyyntilaskut', component: YhteisomyyntilaskutComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/integraatiot-holvi', component: YllapitoHolviComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/integraatiot-verohallinto', component: YllapitoVerohallintoComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/kayttoehdot', component: KayttoehdotComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/sopimukset', component: SopimuksetComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/postmarkapp/dmarc/:raporttiId', component: DmarcRaporttiComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/postmarkapp/dmarc', component: DmarcRaportitComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/pankkiyhteydet', component: PankkiyhteydetComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/raportit', component: ReportsComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/sahkopostipohjat', component: EmailTemplatesComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/massamuikkarit', component: MaksumuistutusComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/ajastetut-tyot', component: YllapitoAjastetutTyotComponent, canActivate: [YllapitoGuard] },
  { path: 'yllapito/massaviesti', component: MassaviestiComponent, canActivate: [YllapitoGuard] },

  { path: 'kirjauduttupois', component: LoggedOutComponent },
  { path: 'kirjaudu', component: LoginComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }

]

export const routing = RouterModule.forRoot(appRoutes, {})
