import { Component, OnInit, OnDestroy, ErrorHandler, Input, OnChanges, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core'

import { Observable, Subject } from 'rxjs'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { DateService } from '../../_shared-core/service/date.service'
import { MassaviestiTemplate } from 'app/_jaettu-lemonator/model/massaviesti'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'


@Component({
  selector: '[app-massaviesti-esikatselu]',
  templateUrl: './massaviesti-esikatselu.component.html',
  styleUrls: ['./massaviesti-esikatselu.component.css']
})

export class MassaviestiEsikatseluComponent implements OnInit, OnDestroy {

  private _ngUnsubsribe: Subject<void> = new Subject<void>()

  @Input() otsikkoObservable: Observable<string>
  @Input() htmlObservable: Observable<string>

  constructor(
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._ngUnsubsribe.next()
  }

}
