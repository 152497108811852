<div class="list-page" *ngIf="{ lahetyksiaJonossa: (lahetyksiaJonossaObservable | async)}  as lahetys ">
  <h1>Massaviestin lähetys</h1>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="block">
      <div class="col">
        <h2>Suomi</h2>
        <mat-form-field class="short-input">
          <mat-label>Aihe</mat-label>
          <input matInput formControlName="aiheFi" />
        </mat-form-field>
        <mat-form-field class="short-input">
          <mat-label>Otsikko</mat-label>
          <input matInput formControlName="otsikkoFi" />
        </mat-form-field>
        <quill-editor style="width: 45em;" #quillFi [formControl]="tekstiFi" [modules]="modules"></quill-editor>
        <!-- <mat-error *ngIf="textfi?.errors?.required && (textfi?.dirty || textfi?.touched)">
              Lisää teksti
            </mat-error> -->
      </div>

      <div app-massaviesti-esikatselu [otsikkoObservable]="otsikkoFi.valueChanges" [htmlObservable]="htmlFiObservable"></div>
    </div>

    <div class="block">
      <div class="col">
        <h2>Englanti</h2>
        <mat-form-field class="short-input">
          <mat-label>Aihe</mat-label>
          <input matInput formControlName="aiheEn" />
        </mat-form-field>
        <mat-form-field class="short-input">
          <mat-label>Otsikko</mat-label>
          <input matInput formControlName="otsikkoEn" />
        </mat-form-field>
        <quill-editor style="width: 45em;" [formControl]="tekstiEn" [modules]="modules"></quill-editor>
        <!-- <mat-error *ngIf="texten?.errors?.required && (texten?.dirty || texten?.touched)">
              Lisää teksti
            </mat-error> -->
      </div>
      <div app-massaviesti-esikatselu [otsikkoObservable]="otsikkoEn.valueChanges" [htmlObservable]="htmlEnObservable"></div>
    </div>

    <div style="max-width: 600px;">
      <button class="secondarybutton" mat-button (click)="test()" type="button">Lähetä mulle testiviesti</button>
    </div>


    <h2 style="margin-top: 5em;">Vastaanottajat</h2>
    <div formGroupName="recipients" class="checkbox-group">
      <mat-checkbox formControlName="sopimus-voimassa">
        Sopimus voimassa
      </mat-checkbox>
      <mat-checkbox formControlName="tauolla">
        Tauolla
      </mat-checkbox>
      <mat-checkbox formControlName="normiasiakas">
        Normiasiakas
      </mat-checkbox>
      <mat-checkbox formControlName="zen">
        Zen
      </mat-checkbox>
      <mat-checkbox formControlName="minduu">
        Minduu
      </mat-checkbox>
    </div>

    <div *ngIf="!lahetys.lahetyksiaJonossa; else lahetysKaynnissa">
      <div style="display: flex; justify-content: space-evenly; margin-top: 1em; margin-bottom: 2em;">
        <button class="secondarybutton" mat-button (click)="save()" type="button">Tallenna</button>
        <button class="primarybutton" mat-button (click)="send()" type="button">Lähetä</button>
      </div>
    </div>

    <ng-template #lahetysKaynnissa>
      <div style="margin: 2em auto; text-align: center;">
        <div style="height: 60px;">
          <i class="icon ion-ios-paper-plane"></i>
        </div>
        <div style="font-size: 15px;">Massaviestin lähetys käynnissä, {{lahetys?.lahetyksiaJonossa}} asiakasta jäljellä.</div>
      </div>
    </ng-template>
  </form>

</div>